import { lazy } from 'react';

// project imports
import Loadable from '../component/Loadable';
import MainLayout from '../layout/MainLayout';

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('../views/dashboard/Default')));

// sample page routing
const XraySafetyTest = Loadable(lazy(() => import('../views/machine/xRay/XraySafetyTest')));
const XraySptTest = Loadable(lazy(() => import('../views/machine/xRay/XraySptTest')));
const WtmdTest = Loadable(lazy(() => import('../views/machine/wtmd/WtmdTest')));
const Profile = Loadable(lazy(() => import('../views/manage/employee/Profile')));

// ==============================|| MAIN ROUTING ||============================== //

const TesterRoutes = {
  path: '/',
  element: <MainLayout />,
  children: [
    {
      path: '/',
      element: <DashboardDefault />
    },
    {
      path: 'dashboard',
      element: <DashboardDefault />
    },
    {
      path: 'profile',
      element: <Profile />
    },
    {
      path: 'xray',
      children: [
        {
          path: 'safetytest',
          element: <XraySafetyTest />
        },
        {
          path: 'spttest',
          element: <XraySptTest />
        }
      ]
    },
    {
      path: 'wtmd',
      children: [
        {
          path: 'safetytest',
          element: <XraySafetyTest />
        },
        {
          path: 'cleantestverification',
          element: <WtmdTest />
        },
        {
          path: 'walkthroughdetection',
          element: <WtmdTest />
        }
      ]
    },
    {
      path: 'rameter',
      children: [
        {
          path: 'safetytest',
          element: <XraySafetyTest />
        }
      ]
    }
  ]
};

export default TesterRoutes;
