// assets
import { IconArrowAutofitRight, IconInfoSquare, IconKey } from '@tabler/icons';

// constant
const icons = {
  IconKey,
  IconArrowAutofitRight,
  IconInfoSquare
};

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const testerPages = {
  id: 'pages',
  type: 'group',
  children: [
    {
      id: 'xray_machine',
      title: 'x-ray_machine',
      type: 'collapse',
      icon: icons.IconArrowAutofitRight,
      children: [
        {
          id: 'safety_test_01',
          title: 'safety_test',
          type: 'item',
          url: '/xray/safetytest'
        },
        {
          id: 'stp_test_01',
          title: 'stp_test',
          type: 'item',
          url: '/xray/spttest'
        }
      ]
    },
    {
      id: 'WTMD_machine',
      title: 'wtmd_machine',
      type: 'collapse',
      icon: icons.IconArrowAutofitRight,
      children: [
        // remove for posti
        // {
        //   id: 'safety_test_02',
        //   title: 'safety_test',
        //   type: 'item',
        //   url: '/wtmd/safetytest'
        // },
        {
          id: 'clean_test_verification_01',
          title: 'menu_clean_safety_test',
          type: 'item',
          url: '/wtmd/cleantestverification'
        },
        {
          id: 'walk_through_detection',
          title: 'menu_walk_test',
          type: 'item',
          url: '/wtmd/walkthroughdetection'
        }
      ]
    },
    {
      id: 'RAMETER_machine',
      title: 'rameter_machine',
      type: 'collapse',
      icon: icons.IconArrowAutofitRight,
      children: [
        {
          id: 'safety_test_06',
          title: 'safety_test',
          type: 'item',
          url: '/rameter/safetytest'
        }
      ]
    }
    // {
    //   id: 'ETD_machine',
    //   title: 'etd_machine',
    //   type: 'collapse',
    //   icon: icons.IconArrowAutofitRight,
    //   children: [
    //     {
    //       id: 'safety_test_03',
    //       title: 'safety_test',
    //       type: 'item',
    //       url: '/etd/safetytest'
    //     }
    //   ]
    // },
    // {
    //   id: 'XRF_machine',
    //   title: 'xrf_machine',
    //   type: 'collapse',
    //   icon: icons.IconArrowAutofitRight,
    //   children: [
    //     {
    //       id: 'safety_test_04',
    //       title: 'safety_test',
    //       type: 'item',
    //       url: '/xrf/safetytest'
    //     }
    //   ]
    // },
    // {
    //   id: 'HHMD machine',
    //   title: 'hhmd_machine',
    //   type: 'collapse',
    //   icon: icons.IconArrowAutofitRight,
    //   children: [
    //     {
    //       id: 'safety_test_05',
    //       title: 'safety_test',
    //       type: 'item',
    //       url: '/hhmd/safetytest'
    //     }
    //   ]
    // }
  ]
};

export default testerPages;
