// assets
import { IconKey, IconArrowAutofitRight, IconInfoSquare } from '@tabler/icons';

// constant
const icons = {
  IconKey,
  IconArrowAutofitRight,
  IconInfoSquare
};

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const adminPages = {
  id: 'pages',
  type: 'group',
  children: [
    {
      id: 'Reports',
      title: 'reports',
      type: 'collapse',
      icon: icons.IconArrowAutofitRight,
      children: [
        {
          id: 'STP_test',
          title: 'stp_test',
          type: 'item',
          url: '/reports/stp'
        },
        {
          id: 'WTMD_test',
          title: 'wtmd_test',
          type: 'collapse',
          // url: '/reports/wtmd'
          children: [
            {
              id: 'clean_test_verification_01',
              title: 'menu_clean_safety_test',
              type: 'item',
              url: '/reports/wtmd/clean'
            },
            {
              id: 'walk_through_detection',
              title: 'menu_walk_test',
              type: 'item',
              url: '/reports/wtmd/walk'
            }
          ]
        },
        {
          id: 'safety_test_07',
          title: 'safety_test',
          type: 'collapse',
          children: [
            {
              id: 'X-RAY_01',
              title: 'X-RAY',
              type: 'item',
              url: '/reports/safety/xray'
            },
            // {
            //   id: 'WTMD_02',
            //   title: 'WTMD',
            //   type: 'item',
            //   url: '/reports/safety/wtmd'
            // },
            {
              id: 'RAMETER_06',
              title: 'RADMETER',
              type: 'item',
              url: '/reports/safety/rameter'
            }
          ]
        }
      ]
    },
    {
      id: 'manage_systems',
      title: 'manage_systems',
      type: 'collapse',
      icon: icons.IconArrowAutofitRight,
      children: [
        {
          id: 'overview_id',
          title: 'manage_overview',
          type: 'item',
          url: 'manage/overview',
          breadcrumbs: false
        },
        {
          id: 'company_id',
          title: 'manage_companies',
          type: 'item',
          url: 'manage/companies',
          breadcrumbs: false
        },
        {
          id: 'devicde_id',
          title: 'manage_devices',
          type: 'item',
          url: 'manage/devices',
          breadcrumbs: false
        },
        {
          id: 'employee_id',
          title: 'manage_employees',
          type: 'item',
          url: 'manage/employees',
          breadcrumbs: false
        },
        {
          id: 'maintenances_id',
          title: 'manage_maintenances',
          type: 'item',
          url: 'manage/maintenances',
          breadcrumbs: false
        }
      ]
    },
    {
      id: 'admin_systems',
      title: 'admin_systems',
      type: 'collapse',
      icon: icons.IconArrowAutofitRight,
      children: [
        {
          id: 'admin_company_id',
          title: 'admin_companies',
          type: 'item',
          url: 'admin/allcompanies',
          breadcrumbs: false
        }
      ]
    }
  ]
};

export default adminPages;
