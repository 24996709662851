import { default as UniversalCookies } from 'universal-cookie';

const cookies = new UniversalCookies();

// export const jwtCookieName = 'jwt';
export const jwtCookieName = 'xraytrainer-qm-jwt';
export const jwtExpiryName = 'expiry';

export const get = (name: string) => {
  return cookies.get(name) || null;
};

export const set = (name: string, value: any, options: any = {}) => {
  return cookies.set(name, value, getCookieOptions(options));
};

export const remove = (name: string, options: any = {}) => {
  return cookies.remove(name, getCookieOptions(options));
};

export const getCookieOptions = (options: any = {}) => {
  return {
    maxAge: 30 * 24 * 3600,
    path: '/',
    ...options
  };
};
