// theme constant
import i18next from 'i18next';
export const gridSpacing = 3;
export const drawerWidth = 260;
export const appDrawerWidth = 320;
export const STATUS_CODE_UNAUTHORIZED = 401;
export const STATUS_CODE_FORBIDEN = 403;

// TEST 1&2 -> minimum is 5
// TEST 3 -> minimum is 2
// TEST 4A -> minimum is 1
// TEST 4B -> minimum is 7
// TEST 5 -> minimum is 1
export const MINIMUM_CHECKED_FOR_PASS_IN_STP = [51336, 18, 2, 508, 1];

export const SEARCH_DURATION_DATE = [
  {
    label: 'today',
    value: '0_days_day'
  },
  {
    label: 'yesterday',
    value: '1_days_day'
  },
  {
    label: 'this_week',
    value: '0_weeks_week'
  },
  {
    label: 'last_week',
    value: '1_weeks_week'
  },
  {
    label: '2_weeks_ago',
    value: '2_weeks_week'
  },
  {
    label: 'this_month',
    value: '0_months_month'
  },
  {
    label: 'last_month',
    value: '1_months_month'
  },
  {
    label: '2_months_ago',
    value: '2_months_month'
  },
  {
    label: '3_months_ago',
    value: '3_months_month'
  },
  {
    label: 'this_year',
    value: '0_years_year'
  },
  {
    label: 'last_year',
    value: '1_years_year'
  }
];

export const TEST_STATUS = { pass: 1, fail: 0 };

export const REPORT_STATUS = [
  {
    label: 'All',
    value: '0,1'
  },
  {
    label: 'pass',
    value: '1'
  },
  {
    label: 'fail',
    value: '0'
  }
];

export const LOCATION = {
  none: 0,
  xray: 1,
  wtmd: 2,
  clean: 2,
  walk: 2,
  etd: 3,
  xrf: 4,
  hhmd: 5,
  stp: 1,
  rameter: 6
};

export const METER_LIST = [
  { name: 'Meter 1', value: 1 },
  { name: 'Meter 2', value: 2 }
];

export const YES_NO = [
  { name: 'No', value: 0 },
  { name: 'Yes', value: 1 }
];

export const STANDARD_USED = [
  { name: 'N/A', value: 'N/A' },
  { name: 'Standard 1', value: 'Standard 1' },
  { name: 'Standard 2', value: 'Standard 2' },
  { name: 'Standard 3', value: 'Standard 3' }
];

export const OPTINUM_POSITION = [
  { name: 'not_used', value: 0 },
  { name: 'left', value: 1 },
  { name: 'middle', value: 2 },
  { name: 'right', value: 3 },
  { name: 'STP Holder', value: 4 }
];

export const ENHANCEMENT = [
  { name: 'not_used_question', value: 0 },
  { name: 'sens_cc_question', value: 1 },
  { name: 'high_question', value: 2 },
  { name: 'bw_question', value: 3 },
  { name: 'negative_question', value: 4 },
  { name: 'inorganic_question', value: 5 },
  { name: 'oraganic_question', value: 6 },
  { name: 'gamma_question', value: 7 }
];

export const CHECKBOX_VALUES = [
  { yes: false, no: false },
  { yes: false, no: false },
  { yes: false, no: false },
  { yes: false, no: false }
];

export const TEST_POSITION = [
  {
    name: 'A',
    description: 'walk_a',
    detection: [
      { yes: false, no: false },
      { yes: false, no: false },
      { yes: false, no: false },
      { yes: false, no: false }
    ]
  },
  {
    name: 'B',
    description: 'walk_b',
    detection: [
      { yes: false, no: false },
      { yes: false, no: false },
      { yes: false, no: false },
      { yes: false, no: false }
    ]
  },
  {
    name: 'C',
    description: 'walk_c',
    detection: [
      { yes: false, no: false },
      { yes: false, no: false },
      { yes: false, no: false },
      { yes: false, no: false }
    ]
  },
  {
    name: 'D',
    description: 'walk_d',
    detection: [
      { yes: false, no: false },
      { yes: false, no: false },
      { yes: false, no: false },
      { yes: false, no: false }
    ]
  },
  {
    name: 'E',
    description: 'walk_e',
    detection: [
      { yes: false, no: false },
      { yes: false, no: false },
      { yes: false, no: false },
      { yes: false, no: false }
    ]
  }
];

export const ENHANCEMENT_UI = [
  {
    name: 'A',
    description: 'Single wire resolution',
    vertical: 'test1Vertical',
    horizontal: 'test1Horizontal'
  },
  {
    name: '2',
    description: 'Useful penetration',
    vertical: 'test2Vertical',
    horizontal: 'test2Horizontal'
  },
  {
    name: '3',
    description: 'Spatial resolution',
    vertical: 'test3Vertical',
    horizontal: 'test3Horizontal'
  },
  {
    name: '4a',
    description: 'Simple penetration(thin material)',
    vertical: 'test4aVertical',
    horizontal: 'test4aHorizontal'
  },
  {
    name: '4b',
    description: 'Simple penetration(thick material)',
    vertical: 'test4bVertical',
    horizontal: 'test4bHorizontal'
  },
  {
    name: '5',
    description: 'Discrimination between materials',
    vertical: 'test5Vertical',
    horizontal: 'test5Horizontal'
  }
];

export const REPORT_TYPE = {
  none: { sefetyTestId: 0, title: '', location: LOCATION.none, apiName: 'none', type: 'none', titleReport: '' },
  stp: {
    sefetyTestId: LOCATION.stp,
    name: 'STP',
    title: 'title_stp_test',
    location: LOCATION.stp,
    apiName: 'stp',
    type: 'stp',
    titleReport: 'title_report_stp_test'
  },
  // wtmdClean: {
  //   sefetyTestId: LOCATION.stp,
  //   name: 'WTMD',
  //   title: 'title_wtmd_test',
  //   location: LOCATION.wtmd,
  //   apiName: 'wtmd',
  //   type: 'clean',
  //   titleReport: 'title_report_wtmd_test'
  // },
  // wtmdClean: {
  //   sefetyTestId: LOCATION.stp,
  //   name: 'WTMD',
  //   title: 'title_wtmd_test',
  //   location: LOCATION.wtmd,
  //   apiName: 'wtmd',
  //   type: 'walk',
  //   titleReport: 'title_report_wtmd_test'
  // },
  safetyXray: {
    sefetyTestId: 15,
    name: 'X-Ray',
    title: 'title_xray_safety_test',
    location: LOCATION.xray,
    apiName: 'safetyreport',
    type: 'safetyXray',
    titleReport: 'title_report_xray_safety_test'
  },

  safetyWtmd: {
    sefetyTestId: 14,
    name: 'WTMD',
    title: 'title_wtmd_safety_test',
    location: LOCATION.wtmd,
    apiName: 'safetyreport',
    type: 'safetyWtmd',
    titleReport: 'title_report_wtmd_safety_test'
  },
  safetyEtd: {
    sefetyTestId: 12,
    name: 'ETD',
    title: 'title_etd_safety_test',
    location: LOCATION.etd,
    apiName: 'safetyreport',
    type: 'safetyEtd',
    titleReport: 'title_report_etd_safety_test'
  },
  safetyXrf: {
    sefetyTestId: 16,
    name: 'XRF',
    title: 'title_xrf_safety_test',
    location: LOCATION.xrf,
    apiName: 'safetyreport',
    type: 'safetyXrf',
    titleReport: 'title_report_xrf_safety_test'
  },
  safetyHhmd: {
    sefetyTestId: 13,
    name: 'HHMD',
    title: 'title_hhmd_safety_test',
    location: LOCATION.hhmd,
    apiName: 'safetyreport',
    type: 'safetyHhmd',
    titleReport: 'title_report_hhmd_safety_test'
  },
  clean: {
    sefetyTestId: LOCATION.clean,
    name: 'CLEAN',
    title: 'title_clean_safety_test',
    location: LOCATION.clean,
    apiName: 'wtmd',
    type: 'clean',
    titleReport: 'title_report_clean_safety_test'
  },
  walk: {
    sefetyTestId: LOCATION.walk,
    name: 'WALK',
    title: 'title_walk_test',
    location: LOCATION.walk,
    apiName: 'wtmd',
    type: 'walk',
    titleReport: 'title_report_walk_test'
  },
  safetyRameter: {
    sefetyTestId: 20,
    name: 'Radmeter',
    title: 'title_rameter_safety_test',
    location: LOCATION.rameter,
    apiName: 'safetyreport',
    type: 'safetyRameter',
    titleReport: 'title_report_rameter_safety_test'
  }
};

// export const SEFETY_TEST = {
//   none: { sefetyTestId: 0, title: '', location: LOCATION.none },
//   xray: { sefetyTestId: 15, title: 'XRAY SAFETY TEST', location: LOCATION.xray },
//   wtmd: { sefetyTestId: 14, title: 'WTMD SAFETY TEST', location: LOCATION.wtmd },
//   etd: { sefetyTestId: 12, title: 'ETD SAFETY TEST', location: LOCATION.etd },
//   xrf: { sefetyTestId: 12, title: 'XRF SAFETY TEST', location: LOCATION.xrf },
//   hhmd: { sefetyTestId: 13, title: 'HHMD SAFETY TEST', location: LOCATION.hhmd }
// };

export const API_MODE_WTMD = ['stp', 'wtmd'];

export const WTMD_TYPE = {
  clean: 1,
  walk: 2
  // clean_v2: 3
};

export const PERSON_ID_HARD_CODE = 1211;

export const SAFETY_TEST_ID = [
  { label: 'ETD', value: 12 },
  { label: 'HHMD', value: 13 },
  { label: 'WTMD', value: 14 },
  { label: 'X-RAY', value: 15 },
  { label: 'XRF', value: 16 },
  { label: 'RADMETER', value: 20 }
];

export const RADIO_ACTTIVE_ETD = [
  { label: 'none_radio_active_source', value: 1 },
  { label: 'radio_active_source', value: 2 }
];

export const RADIO_ACTTIVE_XRF = [
  { label: 'none_radio_active_source', value: 1 },
  { label: 'radio_active_source', value: 2 },
  { label: 'x_ray_tube', value: 3 }
];

export const MACHINE_TYPE = [
  { label: 'X-RAY', value_type: 1, value_safety_test: 15, tests: ['SAFETY', 'STP'], radio: [] },
  // { label: 'WTMD', value_type: 2, value_safety_test: 14, tests: ['SAFETY', 'CLEAN', 'WALK THROUGH DETECTION'], radio: [] },
  { label: 'WTMD', value_type: 2, value_safety_test: 14, tests: ['CLEAN', 'WALK THROUGH DETECTION'], radio: [] },
  { label: 'ETD', value_type: 3, value_safety_test: 12, tests: ['SAFETY'], radio: RADIO_ACTTIVE_ETD },
  { label: 'XRF', value_type: 4, value_safety_test: 16, tests: ['SAFETY'], radio: RADIO_ACTTIVE_XRF },
  { label: 'HHMD', value_type: 5, value_safety_test: 13, tests: ['SAFETY', 'DETECTION'], radio: [] },
  { label: 'RADMETER', value_type: 6, value_safety_test: 20, tests: ['SAFETY'], radio: [] }
];

export const MACHINE_VIEW = [
  { label: 'Dual view', value: 2 },
  { label: 'Single view', value: 1 }
];

export const ROLE_EMPLOYEE_VALUE = {
  tester: 0,
  manager: 1,
  admin: 2
};

export const ROLE_EMPLOYEE = [
  { label: 'Tester', value: ROLE_EMPLOYEE_VALUE.tester },
  { label: 'Manager', value: ROLE_EMPLOYEE_VALUE.manager },
  { label: 'Admin', value: ROLE_EMPLOYEE_VALUE.admin }
];

export const FLAG_DISABLE = [
  { label: 'enabled', value: 0 },
  { label: 'disabled', value: 1 }
];
