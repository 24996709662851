import adminPages from './adminPages';
import dashboard from './dashboard';
// import utilities from './utilities';
import { getRole, ROLE_INFO_OB } from 'utils/common';
import testerPages from './testerPages';
import managerPages from './managerPages';

// ==============================|| MENU ITEMS ||============================== //
let pages = testerPages;
if (getRole() === ROLE_INFO_OB.mamanger) pages = managerPages;
if (getRole() === ROLE_INFO_OB.admin) pages = adminPages;
console.log(getRole());
const menuItems = {
  items: [dashboard, pages]
};

export default menuItems;
