// material-ui
import { Typography } from '@mui/material';

// project imports
import { useEffect, useState } from 'react';
import { ROLE_INFO_OB, getRole } from 'utils/common';
import NavGroup from './NavGroup';

import managerPages from 'menu-items/managerPages';
import dashboard from '../../../../menu-items/dashboard';
import testerPages from '../../../../menu-items/testerPages';
import adminPages from 'menu-items/adminPages';

// ==============================|| SIDEBAR MENU LIST ||============================== //

const MenuList = () => {
  const [navItems, setNavItems] = useState([]);
  useEffect(() => {
    // const pages = getRole() === ROLE_INFO_OB.tester ? testerPages : adminPages;

    let pages = testerPages;
    if (getRole() === ROLE_INFO_OB.mamanger) pages = managerPages;
    if (getRole() === ROLE_INFO_OB.admin) pages = adminPages;

    const menuItems = {
      items: [dashboard, pages]
    };

    // export default menuItems;

    const navs = menuItems.items.map((item) => {
      switch (item.type) {
        case 'group':
          return <NavGroup key={item.id} item={item} />;
        default:
          return (
            <Typography key={item.id} variant="h6" color="error" align="center">
              Menu Items Error
            </Typography>
          );
      }
    });
    setNavItems(navs);
  }, []);
  return <>{navItems}</>;
};

export default MenuList;
