import { lazy } from 'react';

// project imports
import Loadable from '../component/Loadable';
import MainLayout from '../layout/MainLayout';

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('../views/dashboard/Default')));

// utilities routing
const UtilsTypography = Loadable(lazy(() => import('../views/utilities/Typography')));
const UtilsColor = Loadable(lazy(() => import('../views/utilities/Color')));
const UtilsShadow = Loadable(lazy(() => import('../views/utilities/Shadow')));
const UtilsMaterialIcons = Loadable(lazy(() => import('../views/utilities/MaterialIcons')));
const UtilsTablerIcons = Loadable(lazy(() => import('../views/utilities/TablerIcons')));

// sample page routing
// const SamplePage = Loadable(lazy(() => import('../views/sample-page')));
const XraySafetyTest = Loadable(lazy(() => import('../views/machine/xRay/XraySafetyTest')));
const XraySptTest = Loadable(lazy(() => import('../views/machine/xRay/XraySptTest')));
// const WalkThroughDetection = Loadable(lazy(() => import('../views/machine/wtmd/WalkThroughDetection')));
const WtmdTest = Loadable(lazy(() => import('../views/machine/wtmd/WtmdTest')));
const ReportTest = Loadable(lazy(() => import('../views/machine/report/ReportTest')));
const Employees = Loadable(lazy(() => import('../views/manage/employee/Employees')));
const Maintenances = Loadable(lazy(() => import('../views/manage/maintenance/Maintenances')));
const Devices = Loadable(lazy(() => import('../views/manage/device/Devices')));
const Companies = Loadable(lazy(() => import('../views/manage/company/Companies')));
const Manage = Loadable(lazy(() => import('../views/manage/Manage')));
const Profile = Loadable(lazy(() => import('../views/manage/employee/Profile')));

// ==============================|| MAIN ROUTING ||============================== //
const MainRoutes = {
  path: '/',
  element: <MainLayout />,
  children: [
    {
      path: '/',
      element: <DashboardDefault />
    },
    {
      path: 'dashboard',
      element: <DashboardDefault />
    },
    {
      path: 'profile',
      element: <Profile />
    },
    {
      path: 'xray',
      children: [
        {
          path: 'safetytest',
          element: <XraySafetyTest />
        },
        {
          path: 'spttest',
          element: <XraySptTest />
        }
      ]
    },
    {
      path: 'wtmd',
      children: [
        // {
        //   path: 'safetytest',
        //   element: <XraySafetyTest />
        // },
        {
          path: 'cleantestverification',
          element: <WtmdTest />
        },
        {
          path: 'walkthroughdetection',
          element: <WtmdTest />
        }
      ]
    },
    {
      path: 'rameter',
      children: [
        {
          path: 'safetytest',
          element: <XraySafetyTest />
        }
      ]
    },
    {
      path: 'reports',
      children: [
        {
          path: 'stp',
          element: <ReportTest />
        },
        {
          path: 'wtmd/clean',
          element: <ReportTest />
        },
        {
          path: 'wtmd/walk',
          element: <ReportTest />
        },
        {
          path: 'safety/xray',
          element: <ReportTest />
        },
        // {
        //   path: 'safety/wtmd',
        //   element: <ReportTest />
        // },
        {
          path: 'safety/rameter',
          element: <ReportTest />
        }
      ]
    },
    {
      path: 'manage',
      children: [
        {
          path: 'overview',
          element: <Manage />
        },
        {
          path: 'companies',
          element: <Companies />
        },
        {
          path: 'devices',
          element: <Devices />
        },
        {
          path: 'employees',
          element: <Employees />
        },
        {
          path: 'maintenances',
          element: <Maintenances />
        }
      ]
    }
  ]
};

export default MainRoutes;
