/* istanbul ignore file  */
import axios, { AxiosInstance, Method } from 'axios';
import * as storage from './storage';
import * as cookie from './cookie';
import delve from 'dlv';
import { STATUS_CODE_FORBIDEN, STATUS_CODE_UNAUTHORIZED } from './constant';

const getURL = () => {
  return process.env.REACT_APP_API_TEST_URL;
};

const instance: AxiosInstance = axios.create({
  baseURL: getURL(),
  headers: {},
  withCredentials: true,
  timeout: 1000 * 30
});

export interface ResponseMeta {
  pages: number;
  page: number;
  page_size: number;
  total: number;
}

export function fetch<D, R>(url: string, options: { method: Method; data?: D }, header: any = {}): Promise<R> {
  // console.log('storage.get(storage.jwtCookieName)', storage.get(storage.jwtCookieName));
  // try {
  //   if (storage.get(storage.jwtCookieName) === '' || !storage.get(storage.jwtCookieName) || isExpired()) {
  //     storage.remove(storage.jwtCookieName);
  //     storage.remove(storage.jwtExpiryName);
  //     storage.remove(storage.personKey);
  //     window.location.href = '/login';
  //     return Promise.reject('Expired session');
  //   }
  // } catch (error) {
  //   window.location.href = '/login';
  //   return Promise.reject('Expired session');
  // }

  const { method, data } = options;
  let body = data as any;
  let headers = {
    'x-requested-with': 'XMLHttpRequest',
    withCredentials: true,
    ...header
  } as any;

  if (['post', 'put'].indexOf(method) !== -1) {
    if (typeof body === 'string') {
      headers['Content-Type'] = 'application/json';
      headers.Accept = 'application/json, text/plain, */*';
    }
  }

  return instance(url, {
    method,
    data: body,
    headers
  })
    .then((response) => {
      // const status = delve(response, 'response.status');
      // console.log('response----', response);
      return response.data;
    })
    .catch((error) => {
      const status = delve(error, 'response.status');

      if (status === STATUS_CODE_UNAUTHORIZED || status === STATUS_CODE_FORBIDEN) {
        cookie.remove(cookie.jwtCookieName);
        cookie.remove(cookie.jwtExpiryName);
        window.location.href = '/login';
        return Promise.reject('UNAUTHORIZED');
      } else {
        // else throw error;
        throw error;
      }
    });
}

export function fetchWithAuth<D, R>(url: string, options: { method: Method; data?: D }) {
  // if (isExpired()) {
  //   cookie.remove(cookie.jwtCookieName);
  //   cookie.remove(cookie.jwtExpiryName);
  //   window.location.href = '/login';
  //   return Promise.reject('Expired session');
  // }
  const jwt = cookie.get(cookie.jwtCookieName);
  let headers = {};
  if (jwt) {
    headers = {
      Authorization: `Bearer ${jwt}`
    };
  }

  return fetch<D, R>(url, options, headers);
}

function isExpired(): boolean {
  // expiry milliseconds
  const expiry = cookie.get(cookie.jwtExpiryName);
  // Gets the time value in milliseconds
  const currentTime = new Date().getTime();
  return expiry && currentTime >= expiry;
}
